import Scrollspy from 'react-scrollspy'
import React from "react"

export default (props) => (
	<nav className={props.stickyNav ? 'stickyNav nav' : 'nav'}>
	  <Scrollspy  
	    items={ ['section-1', 'section-2', 'section-3'] } 
	    currentClassName="is-current" offset={0}>
	    <li><a href="#section-1">Primary Endpoint</a></li>
	    <li><a href="#section-2">Secondary Endpoints</a></li>
	    <li><a href="#section-3">TG Levels</a></li>
	  </Scrollspy>
	</nav>
)