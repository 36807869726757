import Hero from '../hero/Hero'
import React from "react"

export default (props) => (
	<Hero bckgrdImage={props.bckgrdImage}>
	  <h1 className="headline">
			<span>With VASCEPA,</span> <span className="bold700">early action</span> <span>can translate into significant CV risk* reduction<sup>1-3</sup></span>
				{/* <span>Proven CV risk reduction</span> on top of standard of care, including statins<sup style={{top:"-0.5em"}}>1</sup>*</span> */}
	  </h1>
	  {/*<a  href="https://www.nejm.org/doi/full/10.1056/NEJMoa1812792" target="_blank" rel="noopener noreferrer" className="homeBtn" onClick={props.onClick}>
	      Download the publication
	  </a>*/}
	</Hero>
)
