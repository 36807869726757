import React from "react"
import "./windowshade.css"

const Windowshade = ({ children, id }) => {
  var name = "id"
  let propsId = { id }
  let windowID = propsId[name]

  const openNav = () => {
    let elem = document.getElementById(windowID)
    elem.style.height = "fit-content"
    if (windowID === "Time_to_PCI") {
      setTimeout(function(){
        var divHeight = document.getElementById("Time_to_PCI").clientHeight.toString();
        document.getElementById("Time_to_PCI_under").style.height = divHeight + "px";
      }, 500);
    }
  }

  const closeNav = () => {
    let elem = document.getElementById(windowID)
    elem.style.height = "0%"
    if (windowID === "Time_to_PCI") {
      document.getElementById("Time_to_PCI_under").style.height = "auto"
    }
  }

  return (
    <div className="windowshade-container">
      <button className="windowshade-btn" onClick={openNav}>
        &#43;
      </button>
      <div id={id} className="overlay">
        <div className="overlay-content">{children}</div>

        <button className="windowshade-btn" onClick={closeNav}>
          &#8722;
        </button>
      </div>
    </div>
  )
}

Windowshade.defaultProps = {
  id: "Windowshade",
}

export default Windowshade
