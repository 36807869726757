import React from "react"
import PropTypes from 'prop-types';

import "./collapsible.css"

const Collapsible = (props) => {    
    function handleClick(e) {
        e.preventDefault();
        let targ = e.target
        targ.classList.toggle("col-active");
        let content = targ.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = `${content.scrollHeight}px`;
            // content.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }

    return (
        <div style={{width: props.w}} className={props.containerClass + " " + props.collapsibleWidth}>
            <button className={props.btnClass} onClick={handleClick}>
                {props.title}
            </button>
            <div className={props.contentClass}>
                {props.children}
            </div>
        </div>
    ) 
}

Collapsible.propTypes = {
    containerClass: PropTypes.string,
    containerWidth: PropTypes.string,
    w: PropTypes.string,
    title: PropTypes.oneOfType ([PropTypes.string,PropTypes.array]),
    btnClass: PropTypes.string,
    contentClass: PropTypes.string
};


// Specifies the default values for props:
Collapsible.defaultProps = {
    containerClass: 'collapsible-container',
    collapsibleWidth: 'collapsible-width',
    w: '100%',
    title: 'collapsible btn',
    btnClass: 'collapsible',
    contentClass: 'collapsible-content'
};



export default Collapsible