import React, { Component } from "react"
import Windowshade from "../windowshade/Windowshade"
import Collapsible from "../collapsible/Collapsible"

/* mobile charts */
import PCEChart from "../../images/graphs/mobile/primarycompositeendpoint_chart_mobile_1.png"
import PCEChartGraph from "../../images/graphs/mobile/primarycompositeendpoint_chart_mobile_2.png"
import ReductionsChart from "../../images/graphs/mobile/3reductionsarrow_chart_mobile.png"
import PEAGraph from "../../images/graphs/mobile/primaryendpointbytriglyceride_chart_mobile.png"

/* desktop charts */
import PCEChart_desk1 from "../../images/graphs/desktop/primarycompositeendpoint_chart/Primary-Composite-1@2x.png"
import PCEChart_desk2 from "../../images/graphs/desktop/primarycompositeendpoint_chart/Primary-Composite-2@2x.png"
import PCEChart_desk3 from "../../images/graphs/desktop/primarycompositeendpoint_chart/Primary-Composite-3@2x.png"
import PCEChart_desk4 from "../../images/graphs/desktop/primarycompositeendpoint_chart/Primary-Composite-4@2x.png"
import PCEChart_desk5 from "../../images/graphs/desktop/primarycompositeendpoint_chart/Primary-Composite-5@2x.png"


import ReductionsChart_desk from "../../images/graphs/desktop/other_secondary_endpoint_chart.png"
import PEAGraph_desk1 from "../../images/graphs/desktop/trig_chart/trig-chart-1@2x.png"
import PEAGraph_desk2 from "../../images/graphs/desktop/trig_chart/trig-chart-2@2x.png"
import PEAGraph_desk3 from "../../images/graphs/desktop/trig_chart/trig-chart-3@2x.png"
import PEAGraph_desk4 from "../../images/graphs/desktop/trig_chart/trig-chart-4@2x.png"

import Magnify_icon from "../../images/magnify-icon.svg"

import { Link } from "gatsby"
import "./styles.css"

const CollapseMobile = (props) => {
  return (
    <Collapsible
      title={[
        "VASCEPA CV Outcomes Trial",<br key="2"/>,"(REDUCE-IT",
        <sup key="1" className="sup-align-top-2">®</sup>,
        ") Design",<sup key="3">1</sup>,
      ]}
      collapsibleWidth={"collapsible512 m-center"}
    >
      <p className="body-copy">
        A randomized, double-blind, placebo-controlled trial for adults
        with eCVD or with diabetes and other risk factors, receiving
        statin therapy, and with modestly elevated TG (median baseline 216
        mg/dL) and well-controlled LDL-C (median baseline 75 mg/dL). 8179
        patients (VASCEPA 4 g/d [n=4089]; placebo [n=4090]) were followed
        for a median of 4.9 years.
      </p>
      <p style={{paddingTop:"20px"}} className="body-copy">
        Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received standard-of-care medications including statins (100%), antihypertensives (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%), and beta blockers (71%).
      </p>
    </Collapsible>
  )
}

const CollapseDesk = (props) => {
  return (
    <Collapsible
      title={[
        "VASCEPA CV Outcomes Trial (REDUCE-IT",
        <sup key="1" className="sup-align-top-2">®</sup>,
        ") Design",<sup key="3">1</sup>,
      ]}
      collapsibleWidth={"collapsible512 m-center"}
    >
      <p className="body-copy">
        A randomized, double-blind, placebo-controlled trial for adults
        with eCVD or with diabetes and other risk factors, receiving
        statin therapy, and with modestly elevated TG (median baseline 216
        mg/dL) and well-controlled LDL-C (median baseline 75 mg/dL). 8179
        patients (VASCEPA 4 g/d [n=4089]; placebo [n=4090]) were followed
        for a median of 4.9 years.
      </p>
      <p style={{paddingTop:"20px"}} className="body-copy">
        Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received standard-of-care medications including statins (100%), antihypertensives (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%), and beta blockers (71%).
      </p>

    </Collapsible>
  )
}

export default props => {
  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal;
    const dataHref = evt.target.dataset.href || null;
    props.goModal(dataAtt, dataHref);
  }

  // conditional render see components above
  const isMobile = props.isMobile;
  let collapse;
  if (isMobile) {
    collapse = <CollapseMobile />;
  } else {
    collapse = <CollapseDesk />;
  }

  return (
    <main className="main">
      <section id="section-1" className="endpoint-outcome-sec-one">
        <h3 className="cv-eyebrow dkgray">
          For adults on maximally tolerated statins with TG ≥150 mg/dL and
          established CVD or diabetes and ≥2 CVD risk factors
        </h3>

        <h2 className="cv-headline">
        Adding VASCEPA <span className="bold700">significantly reduced CV events, such as MI or stroke</span>, beyond reductions seen with statins + standard-of-care therapies<sup>1-4&dagger;</sup>
        </h2>
        <div className="windowshade-container">
          <div className="windowshade-header">
            <p className="">See Graph</p>
          </div>
          <Windowshade>
            <div className="shade-copy-container">
              <p className="shade-body-copy">25% RRR;&nbsp;NNT=21</p>
              <p className="shade-body-copy-alt">ARR=4.8%; <em>P</em>=0.00000001</p>
            </div>
            <div className='windowshade-chartheadline-container'>
              <h3 className="windowshade-chartheadline">
                Composite first occurrence of<br/>5-point MACE (primary endpoint)
              </h3>
              <img
                className="magnify_icon"
                data-modal="primaryendpoint_chart_zoom"
                src={Magnify_icon}
                onClick={goModal}
                alt="Composite first occurrence of 5-point MACE (primary endpoint)"
              />
            </div>
            <img className="chart mobile-w-90" src={PCEChartGraph} alt="" />

            <div className="windowshade-footer">
              <p className="">close</p>
            </div>
          </Windowshade>
        </div>
        <div className="chart-container-centering">
          <div className="chart-container">
            <h4 className="cv-chartheadline">
              Composite first occurrence of 5-point MACE (primary endpoint)
            </h4>
            <div className="chartlockup-outcomes animation-container" >
              <img
                className="outcomes-chart cv axis mobile-w-35"
                src={props.isMobile ? PCEChart : PCEChart_desk1}
                alt="Composite first occurrence of 5-point MACE (primary endpoint)"
              />
              <img
                className="outcomes-chart cv animated first-fill"
                src={props.isMobile ? null : PCEChart_desk2}
                alt="Composite first occurrence of 5-point MACE (primary endpoint)"
              />
              <img
                className="outcomes-chart cv animated second-fill"
                src={props.isMobile ? null : PCEChart_desk3}
                alt="Composite first occurrence of 5-point MACE (primary endpoint)"
              />
              <div className="line-container">
              <img
                  className="outcomes-chart cv animated "
                  src={props.isMobile ? null : PCEChart_desk4}
                  alt="Composite first occurrence of 5-point MACE (primary endpoint)"
                />
              <div className="line-mask">

              </div>
                
              </div>
              
              <img
                className="outcomes-chart cv animated third-fill"
                src={props.isMobile ? null : PCEChart_desk5}
                alt="Composite first occurrence of 5-point MACE (primary endpoint)"
              />
              {props.isMobile ? null :
                <img
                className="magnify_icon"
                data-modal="primaryendpoint_chart_zoom"
                src={Magnify_icon}
                onClick={goModal}
                alt="Composite first occurrence of 5-point MACE (primary endpoint)"
              />}


            </div>
            <p className="cv-chart-footnote">At Year 5 since randomization, 1430 patients remained in the VASCEPA
              arm vs 1358 patients in the placebo arm.
            </p>
            <p className="cv-chart-footnote">
              ARR=absolute risk reduction; MACE=major adverse cardiovascular events; NNT=number needed to treat; RRR=relative
              risk reduction.
            </p>
          </div>

          <div className="chart-sidebar">
            <ul className="content-list">
              <li className="primary-list-item">
                5-point MACE primary composite endpoint was defined as Nonfatal MI,
                Nonfatal Stroke, CV Death, Coronary Revascularization, or Unstable
                Angina Requiring Hospitalization
              </li>
              <li className="primary-list-item">
              Patients in the Placebo + Statin arm had a cumulative 28.3% event rate, which is consistent for this high-risk population<sup class="sup-8">3</sup>
              </li>
            </ul>
          </div>

        </div>

        <div className="outcomes-callout">
          {collapse}

          <div className="cta-container m-center fit-content">
            <h3 className="cta-copy">
              See the data in the <em>NEJM</em> publication
            </h3>
            <button data-modal="leavingsite-modal"
              data-href="https://www.nejm.org/doi/full/10.1056/NEJMoa1812792"
              onClick={goModal}
              className="cta-btn">View now
            </button>

          </div>
        </div>

      </section>
      <hr/>

      <section id="section-2" className="endpoints">
        <h2 className="cv-headline">
        Significantly reduced the risk of <span className="bold700">MI, stroke, or CV death</span><sup>1-3</sup>
        </h2>
        <div className="chart-container-centering">
          <div className="chart-container">
            <div className="chartlockup">
              <img
                className="chart mobile-p-x-10"
                src={props.isMobile ? ReductionsChart : ReductionsChart_desk}
                alt="Significantly reduced the risk of MI, stroke, or CV death"
              />
              <img
                className="magnify_icon"
                data-modal="chart-1.2-2"
                src={Magnify_icon}
                onClick={goModal}
                alt="Significantly reduced the risk of MI, stroke, or CV death"
              />
            </div>
            <p className="cv-chart-footnote">
              NNT=number needed to treat; RRR=relative risk reduction.
            </p>
            <p className="cv-chart-footnote">
              At Year 5 since randomization, 1562 patients remained in the VASCEPA arm vs 1487 patients in the placebo arm.
            </p>
          </div>

          <div className="">
            <ul className="content-list">
              <li className="primary-list-item">
                3-point MACE key secondary composite endpoint was defined as
                Nonfatal MI, Nonfatal Stroke, or CV Death
              </li>
              <li className="primary-list-item">
                35% reduction in urgent or emergent Coronary Revascularization; <em>P</em>&#x3c;0.001
              </li>
              <li className="primary-list-item">
                32% reduction in Unstable Angina Requiring Hospitalization; <em>P</em>=0.002
              </li>
              <li className="primary-list-item">
                71% of studied patients had a prior CV event
              </li>
            </ul>
          </div>
        </div>

        <div className="outcomes-callout">
          <div className="cta-container m-center fit-content no-margin">
            <h3 className="cta-copy">Get patients started on VASCEPA</h3>
            <a className="cta-btn" href="/getting-patients-started/">
              Learn how
            </a>
          </div>
        </div>
      </section>

      <hr/>

      <section id="section-3" className="endpoints no-bottom-border">
        <h2 className="cv-headline">
          Robust CV risk reduction{" "}
          <span className="bold700">
          irrespective of achieved TG levels
          </span><sup>1,2</sup>
        </h2>

        <div className="chart-container-centering">
          <div className="chart-container">
            <h4 className="cv-chartheadline">
              Primary endpoint by achieved triglyceride level at 1 year
            </h4>
            <div className="chartlockup animation-container">
              <img
                className="chart axis"
                src={props.isMobile ? PEAGraph : PEAGraph_desk1}
                alt="Primary endpoint by achieved triglyceride level at 1 year"
              />
              <div className="line-container one">
                <img
                  className="chart animated graph-1"
                  src={props.isMobile ? null : PEAGraph_desk2}
                  alt="Primary endpoint by achieved triglyceride level at 1 year"
                  
                />
                <div className="line-mask">

                </div>
              </div>
              <div className="line-container two">
              <img
                className="chart animated graph-2"
                src={props.isMobile ? null : PEAGraph_desk3}
                alt="Primary endpoint by achieved triglyceride level at 1 year"
                
              />
              <div className="line-mask-second">

              </div>
              </div>

              <div className="line-container three">
              <img
                className="chart animated "
                src={props.isMobile ? null : PEAGraph_desk4}
                alt="Primary endpoint by achieved triglyceride level at 1 year"
                
              />
              <div className="line-mask-2">

              </div>
              </div>
              
              
              <img
                className="magnify_icon"
                data-modal="trig_chart_zoom"
                src={Magnify_icon}
                onClick={goModal}
                alt=""
              />
            </div>
            <p className="cv-chart-footnote">
              Reproduced from <em>The New England Journal of Medicine</em>, Vol 380,
              Bhatt DL, Steg PG, Miller M, et al. Cardiovascular risk reduction with
              icosapent ethyl for hypertriglyceridemia, pp 11-22. Copyright 2019,
              with permission from Massachusetts Medical Society
              <br />
            </p>
            <p className="cv-chart-footnote">
              At Year 5 since randomization, 571 patients with TG &#x3c;150 mg/dL
              and 803 patients with TG ≥150 mg/dL remained in the VASCEPA arm vs
              1358 patients in the placebo arm.
            </p>
          </div>

          <div className="chart-sidebar">
            <ul className="content-list">
              <li className="primary-list-item">
                Median percent change in TG for VASCEPA 4 g/d compared to placebo
                was -19.7%. Median percent change in LDL-C for VASCEPA 4 g/d
                compared to placebo was -6.6%
              </li>
              <li className="primary-list-item">
                VASCEPA has multiple effects that extend beyond TG lowering, including increased EPA lipid composition from carotid plaque specimens. Direct clinical meaning of the findings is not clear
              </li>
            </ul>
          </div>
        </div>

        <div className="outcomes-callout">
          <div className="grad-border">
            <p className="callout-copy">
              Indicate “Dispense as written (DAW-1)” or “Brand medically necessary” to ensure patients are getting the CV benefits of VASCEPA<sup className="callout-copy m-0">1</sup>
            </p>

          </div>
        </div>
      </section>
    </main> /* end main*/
  )
}
