import React, { Component } from "react"
import { Helmet } from "react-helmet"

import Header from "../components/header/Header"
import HeroImage from "../components/1.2_cv-outcomes/HeroImage"
import SideNavigation from "../components/1.2_cv-outcomes/SideNavigation"
import Main from "../components/1.2_cv-outcomes/Main"

import ISI from "../components/isi/ISI"
import Footer from "../components/footer/Footer"
import Modal from "../components/modal/Modal"
import HomeModal from "../components/modal/HomeModal"

import { Waypoint } from "react-waypoint"
import SEO from "../components/seo"

import Loader from "../components/loader"

/* styles */
import "../components/1.0_cv_outcomes/cv_outcomes.css"

export default class OData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      isMobile: false,
      modal: null,
      hideNav: false,
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches
    this.setState({ isMobile: bool })
  }

  goModal = (modal, href) => {
    this.setState(() => ({
      modal: modal,
      href: href,
    }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null, href: null }))
  }

  handleWaypointEnter = () => {
    this.setState(() => ({ hideNav: false }))
  }

  handleWaypointLeave = () => {
    this.setState(() => ({ hideNav: true }))
  }

  handleFooterEnter = () => {
    // this.setState(() => ({ hideNav: false }))
  }

  handleFooterLeave = () => {
    // this.setState(() => ({ hideNav: true }))
  }

  render() {
    return (
      <>
      <Loader>
      <SEO
        title="CV Outcomes-The Data"
        keywords={[""]}
        description="Read the data describing unprecedented reduction in persistent cardiovascular risk (P-CVR). Adding VASCEPA 4 g/d to a statin showed a 25% relative risk reduction (RRR) in CV events and significant reduction in key secondary composite endpoint of MI, Stroke, and CV Death. Learn more about VASCEPA outcomes data."/>
        <HomeModal/>
        <Modal modal={this.state.modal} modalClosed={this.modalClosed} href={this.state.href}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage bckgrdImage={'header1-2'}/>
        <Waypoint
         onEnter={this.handleWaypointEnter}
         onLeave={this.handleWaypointLeave}/>
        <div className="wrapper">
          <div className="grid">
            <SideNavigation stickyNav={this.state.hideNav}/>
            <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
          </div>
          
        </div>
        <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          <div className="ref-outer">
            <div className="footnotes-container">
              <div className="footnotes">
                <p className="cv-footnote">
                ACE=angiotensin-converting enzyme; ARBs=angiotensin II receptor blockers; CI=confidence interval; CV=cardiovascular; CVD=cardiovascular disease; eCVD=established cardiovascular disease; EPA=eicosapentaenoic acid; LDL-C=low-density lipoprotein cholesterol; MI=myocardial infarction; TG=triglyceride.
                </p>
                <p className="cv-footnote hang">
                  *Cardiovascular risk associated with events including myocardial 
                  infarction, stroke, CV death, coronary revascularization, and unstable angina requiring hospitalization.
                </p>
                <p className="cv-footnote hang">
                  <sup>†</sup>Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received standard-of-care medications 
                  including statins (100%), antihypertensives (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%), and beta blockers (71%).
                </p>
                <p className="cv-footnote hang">
                  <sup>‡</sup>First occurrence of event.
                </p>
              </div>
            </div>
            <div className="footnotes-container">
              <div className="references">
                <p className="cv-footnote">
                  <strong>References: 1. </strong>VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021. 
                  <strong> 2.</strong> Bhatt DL, Steg PG, Miller M, et al; for the REDUCE-IT Investigators. Cardiovascular risk reduction with icosapent ethyl for hypertriglyceridemia. <em>N Engl J Med.</em> 2019;380(1):11-22. 
                  <strong> 3.</strong> Bhatt DL, Steg PG, Miller M, et al. Reduction of cardiovascular events with icosapent ethyl–intervention trial. Presented at: American Heart Association Scientific Sessions; November 10-12, 2018; Chicago, IL.
                  {" "}<strong>4. </strong> Bhatt DL, Steg PG, Miller M, et al; on behalf of the REDUCE-IT Investigators. Effects of icosapent ethyl on total ischemic events: from REDUCE-IT. <em>J Am Coll Cardiol</em>. 2019;73(22):2791-2802.
                </p>
              </div>
            </div>
          </div>
          <Waypoint
            onEnter={this.handleFooterEnter}
            onLeave={this.handleFooterLeave}
          />
          <Footer />
        </Loader>
      </>
    )
  }
}
